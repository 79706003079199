input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="range"]::-webkit-slider-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  pointer-events: auto;
  -webkit-appearance: none;
  background: #1098f7;
}

input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  pointer-events: auto;
  -moz-appearance: none;
  background: #1098f7;
}
