.whatsAppBtn {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1ad142;
  border-radius: 50%;
  padding: 7px;
  cursor: pointer;
}

.whatsAppBtn.isFixed {
  width: 67px;
  height: 67px;
  position: fixed;
  right: 25px;
  bottom: 25px;
  z-index: 99;
}

.whatsAppBtn img {
  max-width: 100%;
}

@media (min-width: 768px) {
  .whatsAppBtn.isFixed {
    right: 50px;
    bottom: 50px;
  }
}
